import { useState } from "react";
import { Carousel, Card } from "react-bootstrap";
import CarouselImage from "./CarouselImage.jsx";
import roof1 from "../../images/roof1.jpg";
import roof2 from "../../images/roof2.jpg";
import roof3 from "../../images/roof3.jpg";
import roof4 from "../../images/roof4.jpg";

function CarouselSection() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div>
      <Carousel
        activeIndex={index}
        indicators={false}
        controls={false}
        onSelect={handleSelect}
        interval={2000}
        fade
      >
        <Carousel.Item>
          <CarouselImage src={roof1} alt="Image of a roof one" />
        </Carousel.Item>
        <Carousel.Item>
          <CarouselImage src={roof2} alt="Image of a roof two" />
        </Carousel.Item>
        <Carousel.Item>
          <CarouselImage src={roof3} alt="Image of a roof three" />
        </Carousel.Item>
        <Carousel.Item>
          <CarouselImage src={roof4} alt="Image of a roof four" />
        </Carousel.Item>
      </Carousel>
      <div className="container">
        <Card className="floating-card py-2 px-2">
          <h3>
            Randles Roofing Services are based in Todmorden, offering all
            aspects of roofing services predominantly throughout West Yorkshire,
            Lancashire and Greater Manchester.
          </h3>
        </Card>
      </div>
    </div>
  );
}

export default CarouselSection;
