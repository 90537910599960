import ServicesTiles from "./services/ServicesTiles";
import { Card } from "react-bootstrap";

function ServicesSection() {
  return (
    <div className="container text-start mb-4 fadeInUp-animation">
      <Card className="d-flex flex-row flex-wrap">
        <Card.Header className="w-100">
          <h4>Services</h4>
        </Card.Header>
        <Card.Title className="card-body px-4 pb-0 mb-0">
          See some of the services that we provide:
        </Card.Title>
        <Card.Body className="w-100 d-flex flex-row flex-wrap justify-content-sm-evenly px-4 m-auto gap-2">
          <ServicesTiles></ServicesTiles>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ServicesSection;
