import { Card } from "react-bootstrap";
import stars from "../../images/stars.svg";
import profile from "../../images/profile.png";

function TestimonialsSection() {
  const reviewText = [
    {
      name: "Derek Allen",
      content:
        "We had problems with raining in around chimney stack. Tried lots of roofers but couldn't get anyone to come and look. Rang Simon who came within 48 hours, got his ladders out and after inspecting quoted on the spot for repointing, sealing lead work and silicone treatment. Very reasonable quote and did the work within three weeks (due to weather). Very satisfied with response, service and work/tidiness etc. Would use again and would recommend.",
    },
    {
      name: "Kathryn Knowles",
      content:
        "I would highly recommend Simon and his team. \nTheir service was professional throughout. \n-Turned up on time when agreed \n- Pre booked a specific date for the work to be carried out (and stuck to it) \n- A fair price for a high quality of work \n- Work was carried out swiftly arriving on time and getting the job done \n- Nice people to do business with \nMore tradesmen could learn a lot from Randles Roofing. \nThanks guys!",
    },
    {
      name: "Michael O'Connell",
      content:
        "I have to to thank Simon and the team for a job well done on our roof. After all the bad weather we had a few tiles come off and were having a leak. After ringing round a few companies but getting nowhere and no one being particularly helpful to be honest. When I rang Randles and Simon answered it was great to speak to someone who would give you the time of day and explain what needed to be done but also that he would come and quote 'no obligation' as well. Needless to say we used Simon for this work and I am so pleased we did. Thank you Simon and all the team at Randles.",
    },
    {
      name: "Nick Gwatkin",
      content:
        "Great work by Simon and his team, efficient, completed in good time and a good tidy up on completion.",
    },
    {
      name: "Ian Chapman",
      content:
        "Simon was very professional from the start of the process to the end. The work was completed to a very high standard with constant updates and communication throughout. I would definitely recommend him.",
    },
    {
      name: "Steve Wright",
      content:
        "Very prompt, clean site and great work.  Can highly recommend.",
    },
  ];

  return (
    <div className="container text-start mt-sm-2 mb-sm-4 d-flex flex-row flex-wrap justify-content-sm-evenly reviews fadeInUp-animation">
      {reviewText.map((item, index) => (
        <Card className={(index === 1 || index === 4) ? "triple-card middle-card ml-auto my-4" : "triple-card ml-auto my-4" }>
          <Card.Body className="d-flex flex-row flex-wrap py-3">
            <Card.Img
              className="profile"
              loading="lazy"
              decoding="async"
              src={profile}
              alt="profile image"
              width="99"
              height="99"
            />
            <Card.Text className="pt-4 text-prewrap">{item.content}</Card.Text>
            <div className="d-flex flex-row align-items-center mt-auto w-100">
              <Card.Text className="w-auto mb-0 mx-auto text-prewrap">
                {item.name}
              </Card.Text>
              <Card.Img
                className="w-auto mx-auto"
                aria-hidden="true"
                loading="lazy"
                decoding="async"
                src={stars}
                alt="stars"
                width="91"
                height="15"
              />
            </div>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
}

export default TestimonialsSection;
