import CarouselSection from "../components/carousel/CarouselSection.jsx";
import TestimonialsSection from "../components/cards/TestimonialsSection.jsx";

function Testimonials() {
  return(
  <div>
    <CarouselSection></CarouselSection>
    <TestimonialsSection></TestimonialsSection>
  </div>)
}

export default Testimonials;
