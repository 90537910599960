import { Card } from "react-bootstrap";
import "../../styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="container d-flex flex-row flex-wrap py-3 justify-content-between">
        {" "}
        <Card className="triple-card mx-auto">
          <div>
            <h5>
              <b>Get in touch</b>
            </h5>
            <p>
              <b>Address</b>
            </p>
            <p>12 Lumbutts Road, Todmorden</p>
            <p>OL14 6PQ</p>
          </div>
          <div>
            <p>
              <b>Phone Number</b>
            </p>
            <p>Mobile: 07717416468</p>
            <p>Landline: 01706819244</p>
          </div>
        </Card>
        <Card className="triple-card">
          <div>
            <h5>
              <b>Useful links</b>
            </h5>
            <h5>
              <b>Social Media</b>
            </h5>
            <p>
              <a href="https://www.facebook.com/RandlesRoofing">Facebook</a>
            </p>
          </div>
        </Card>
        <Card className="triple-card">
          <div>
            <h5>
              <b>Site map</b>
            </h5>
            <h5>
              <b>Address</b>
            </h5>
            <p>
              <Link to="/">Home</Link>
            </p>
            <p>
              <Link to="/portfolio">Portfolio</Link>
            </p>
            <p>
              <Link to="/testimonials">Testimonials</Link>
            </p>
            <p>
              <Link to="/contact">Contact us</Link>
            </p>
          </div>
        </Card>
      </div>
    </footer>
  );
}

export default Footer;
