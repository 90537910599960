import CarouselSection from "../components/carousel/CarouselSection.jsx";
import PortfolioImages from "../components/portfolio/PortfolioSection.jsx";

function Portfolio() {
  return (
    <div>
      <CarouselSection></CarouselSection>
      <PortfolioImages></PortfolioImages>
    </div>
  )
}
            
export default Portfolio;
