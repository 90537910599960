import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

function ThreeCardSection() {
  return (
    <div className="container text-start mt-sm-2 mb-sm-4 d-flex flex-row flex-wrap fadeInUp-animation justify-content-between">
      <Card className="triple-card my-4">
        <Card.Body className="card-block px-2">
          <Card.Title>Portfolio</Card.Title>
          <Card.Text>See some of the images we've captured</Card.Text>
          <Button as={Link} to="/portfolio">
            Services
          </Button>
        </Card.Body>
      </Card>
      <Card className="triple-card middle-card my-4">
        <Card.Body className="card-block px-2">
          <Card.Title>Testimonials</Card.Title>
          <Card.Text>See some reviews of our work</Card.Text>
          <Button as={Link} to="/testimonials">
            Testimonials
          </Button>
        </Card.Body>
      </Card>
      <Card className="triple-card my-4">
        <Card.Body className="card-block px-2">
          <Card.Title>Contact Us</Card.Title>
          <Card.Text>Find out how to contact us</Card.Text>
          <Button as={Link} to="/contact">
            Contact us
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ThreeCardSection;
