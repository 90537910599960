import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import React, { useState } from "react";
import axios from "axios";

function ContactUsSection() {
  const [formComplete, setFormComplete] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/emailrequest", {
        headers: { "Content-Type": "application/json" },
        name: formData.name,
        email: formData.email,
        number: formData.number,
        message: formData.message,
      })
      .then(function (response) {
        if (response.status === 200) {
          setFormComplete(!formComplete);
        } else {
          setFormError(!formError);
        }
      });
    sessionStorage.setItem("email-request", true);
  };
  const handleMultipleSubmit = () => {
    if (sessionStorage.getItem("email-request") === true) {
      setFormComplete(false);
    }
  };

  return (
    <div className="container text-start mb-sm-4 fadeInUp-animation">
      {handleMultipleSubmit()}
      <Card className="flex flex-row flex-wrap">
        <Card.Header className="w-100">
          <h4>Contact Us</h4>
        </Card.Header>
        <Card.Body className="w-50 card-block px-4 mb-auto">
          <Card.Text>
            <h5>
              Please contact us using the information down below or by using our
              email service. We look forward to hearing from you.
            </h5>
            <div className="py-3">
              <b>Address</b>
              <br />
              <span>12 Lumbutts Road, Todmorden</span>
              <br />
              <span>OL14 6PQ</span>
            </div>
            <div>
              <span>
                <b>Phone Number</b>
              </span>
              <br />
              <span>Mobile: 07717416468</span>
              <br />
              <span>Landline: 01706819244</span>
            </div>
          </Card.Text>
        </Card.Body>
        {formError ? (
          <Card.Body
            id="form-confirmation"
            className="w-50 card-block px-4 mb-auto"
          >
            <Card.Title>
              There has been an error! Please try again later
            </Card.Title>
          </Card.Body>
        ) : !formComplete ? (
          <Card.Body
            id="contact-us-form"
            className="w-50 card-block px-4 mb-auto"
          >
            <Form action="/emailrequest" method="post" onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label className="visually-hidden">Name*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name*"
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label className="visually-hidden">Email*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="name@example.com*"
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  required
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formPhone">
                <Form.Label className="visually-hidden">
                  Phone number*
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Phone number"
                  onChange={(e) =>
                    setFormData({ ...formData, number: e.target.value })
                  }
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formMessage">
                <Form.Label className="visually-hidden">Message*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Message*"
                  onChange={(e) =>
                    setFormData({ ...formData, message: e.target.value })
                  }
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="By filling out this form and accepting below, you are giving explicit consent to us contacting you."
                  required
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Send
              </Button>
            </Form>
          </Card.Body>
        ) : (
          <Card.Body
            id="form-confirmation"
            className="w-50 card-block px-4 mb-auto"
          >
            <Card.Title>Thank you! We'll be in touch soon!</Card.Title>
          </Card.Body>
        )}
      </Card>
    </div>
  );
}

export default ContactUsSection;
