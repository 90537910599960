import CarouselSection from "../components/carousel/CarouselSection.jsx";
import ContactUsSection from "../components/cards/contact/ContactUsSection.jsx";

function ContactUs() {
  return (
    <div>
      <CarouselSection></CarouselSection>
      <ContactUsSection></ContactUsSection>
    </div>
  );
}

export default ContactUs;