import { Card } from "react-bootstrap";

function ServicesTiles() {
  const servicesText = [
    "Roof Repair & replacement",
    "Heritage work",
    "Velux Skylights Roof Windows",
    "Leadwork",
    "Chimney repair & repointing",
    "Insurance roof work",
    "Guttering",
    "Fascia & Soffits",
    "EPDM Rubber Flat Roofing",
    "Insulation",
    "Roof lanterns",
    "Roofing projects"
  ];

  return (
    <>
      {servicesText.map((service, index) => (
        <Card key={"service" + (index + 1)} className="card-body w-30 mx-auto">
            <Card.Text>{service}</Card.Text>
        </Card>
      ))}
    </>
  );
}

export default ServicesTiles;
