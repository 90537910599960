import { Image } from "react-bootstrap";

function CarouselImage(props) {
  let src = props.src;
  let alt = props.alt;
  return(
  <div>
    <Image
      src={src}
      width="100%"
      height="400px"
      className="d-inline-block align-middle object-fit-cover"
      alt={alt}
    />
  </div>)
}

export default CarouselImage;
