import { Image } from "react-bootstrap";
import rowImageOne from "../../images/portfolio-images/rowImageOne.jpg";
import rowImageTwo from "../../images/portfolio-images/rowImageTwo.jpg";
import rowImageThree from "../../images/portfolio-images/rowImageThree.jpg";
import rowImageFour from "../../images/portfolio-images/rowImageFour.jpg";
import rowImageFive from "../../images/portfolio-images/rowImageFive.jpg";
import rowImageSix from "../../images/portfolio-images/rowImageSix.jpg";

function PortfolioImages() {
  const images = [
    rowImageOne, rowImageTwo, rowImageThree, rowImageFour, rowImageFive, rowImageSix
  ]

  return (
      <div className="portfolio-container container d-flex flex-wrap justify-content-between fadeInUp-animation">
      {images.map((item, index) => (
          <Image className={(index === 1 || index === 4) ? "triple-card object-fit-scale mb-4 middle-card" : "triple-card object-fit-scale mb-4"}  src={item}></Image>
      ))}
      </div>
  );
}
export default PortfolioImages;
