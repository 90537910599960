import { Card } from "react-bootstrap";
import logo from "../../../images/logo-white.png";

function AboutUsSection() {
  return (
    <div className="container text-start mb-4 fadeInUp-animation">
      <Card className="flex flex-row flex-wrap">
        <Card.Header className="w-100">
          <h4>About Us</h4>
        </Card.Header>
        <Card.Img className="w-50 card-header border-0 p-0 m-auto" src={logo} />
        <Card.Body className="w-50 card-block px-4 m-auto">
          <Card.Text>
            Randles Roofing owned and lead by Simon Randles who learnt his trade
            whilst working in the Family Business with his grandfather 40 years
            ago. With a lifetime of experience and knowledge he and his select
            workforce pride themselves on delivering excellent results within
            customer budget and on time.
            <br />
            <br />
            Our reputation in the roofing trade is proven through the resulting
            completed projects, always built to last, using the best quality
            materials. Please view our roofing work and read what our customers
            say in our testimonials. We believe in delivering excellent results
            through giving customers a professional service and quality
            throughout each job. That's why we have so many jobs via previous
            customer recommendations and excellent reviews. We offer a hassle
            free, quick and reliable service. Please don't hesitate to contact
            us for a no obligation, free quotation.
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default AboutUsSection;
