import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Testimonials from "./pages/Testimonials";
import Portfolio from "./pages/Portfolio";
import NoPage from "./pages/NoPage";
import Header from "./components/headerfooter/Header.jsx";
import Footer from "./components/headerfooter/Footer.jsx";
import {isMobile} from 'react-device-detect';
import "./styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import ContactUs from "./pages/ContactUs";

function App() {
  function deviceRender() {
    if (isMobile) {
      document.getElementById("root").classList.add("mobile");
    } else {
      document.getElementById("root").classList.add("desktop");
    }
  }
  return (
    <>
      {deviceRender()}
      <BrowserRouter>
        <Header />
        <Routes>
          <Route index element={<Home />} />
          <Route path="services" element={<Services />} />
          <Route path="testimonials" element={<Testimonials />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="contact" element={<ContactUs />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

export default App