import { Navbar, Container, Nav, Image } from "react-bootstrap";
import logo from "../../images/logo-white.png";
import { Link } from "react-router-dom";

function Header() {
  return (
    <>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <Image
              src={logo}
              width="200"
              height="auto"
              className="d-inline-block align-middle"
              alt="Randles roofing and building services logo"
              fluid
            />
          </Navbar.Brand>
          <Nav>
            <Nav.Link as={Link} to="/portfolio">
              Portfolio
            </Nav.Link>
            <Nav.Link as={Link} to="/testimonials">
              Testimonials
            </Nav.Link>
            <Nav.Link as={Link} to="/contact">
              Contact us
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
