import CarouselSection from "../components/carousel/CarouselSection.jsx";
import AboutUsSection from "../components/cards/about/AboutUsSection.jsx";
import ServiceSection from "../components/cards/ServicesSection.jsx";
import ThreeCardSection from "../components/cards/ThreeCardSection.jsx";

function Home() {
  return (
    <div className="main">
      <CarouselSection></CarouselSection>
      <AboutUsSection></AboutUsSection>
      <ServiceSection></ServiceSection>
      <ThreeCardSection></ThreeCardSection>
    </div>
  );
}

export default Home;
